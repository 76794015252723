
<template>
  <blog-post-template
    :postId="postId"
    :postTitle="postTitle"
    :postContent="postContent"
    :postSlug="postSlug"
  ></blog-post-template>
</template>

<script>
/* eslint-disable */
import BlogPostTemplate from '@/components/BlogPostTemplate.vue';

export default {
  components: {
    BlogPostTemplate,
  },
  props: {
    postId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      postId: '83',
      postTitle: `Top 17 Online Jobs for College Students`,
      postContent: `
        <p>Hey, college students! Are you tired of spending your weekends binge-watching Netflix and eating your weight in ramen? What if I told you that you could make some extra cash without leaving your dorm room? Yes, you heard that right. There are plenty of online jobs that require zero experience and allow you to work around your hectic class schedule. Intrigued? Stick around, and I\'ll spill the tea. ☕</p>

<h2>1. Freelance Writing</h2>
<p>If you have a knack for words, freelance writing might just be your calling. You can write for blogs, websites, and businesses, turning your free hours into cash. An entry-level writer can earn between $15-$30 per hour. Not too shabby, right? Need some guidance? Check out Holly Johnson’s free training on building a six-figure writing career. You’ll thank me later.</p>

<h2>2. Start a Blog</h2>
<p>Blogging is like planting a money tree. 🌳 You can earn through ads, sponsored posts, and affiliate marketing. I started my first blog in college and even sold one for over $3,000! Imagine earning passive income while you sleep. If you’re clueless about where to start, there are step-by-step guides to help you kick off your blogging journey.</p>

<h2>3. Transcription</h2>
<p>Got good ears? Transcription might be your gig. You’ll listen to audio files and type them out. Simple, right? Beginner transcriptionists can earn around $12-$15 per hour. Companies like Scribie and Rev are always hiring. Get yourself a good pair of headphones and start typing away!</p>

<h2>4. Proofreading</h2>
<p>If you cringe at grammatical errors, proofreading is your jam. You can earn between $15 and $44 per hour by spotting and correcting mistakes in manuscripts, websites, and other content. Companies like Cactus Communications and ProofreadingPal are great places to start. Consider taking a free proofreading workshop by Caitlin Pyle to get a head start.</p>

<h2>5. Paid Online Surveys</h2>
<p>Got 15-20 minutes to spare? Paid online surveys pay you for your opinions on products and trends. Sites like Swagbucks and Survey Junkie are legit. It’s not going to make you rich, but it’s a quick way to earn some pocket money without breaking a sweat.</p>

<h2>6. Freelance Web Designer</h2>
<p>If you know your way around HTML and CSS, freelance web design can be quite lucrative. You can make an average of $23 per hour. Platforms like Upwork and Fiverr have gigs waiting for you. If you’re new to this, plenty of online courses can get you up to speed.</p>

<h2>7. Social Media Manager</h2>
<p>Are you glued to Instagram and Twitter? Why not get paid for it? As a social media manager, you’ll handle posts, comments, and messages for businesses. Part-time managers can earn around $1,000 per month. Check out some social media marketing courses to get started.</p>

<h2>8. Captioning</h2>
<p>Captioning involves creating subtitles for videos and movies. It’s a bit like transcription but with visuals. Companies like Crowdsurf and Rev offer positions. It’s a decent side hustle, paying around $240 per month, and you can do it in your pajamas. 🛋️</p>

<h2>9. Video Editing</h2>
<p>If you’re the Spielberg of your friend group, consider video editing. Content creators are always looking for editors to polish their videos. Freelance platforms like Fiverr and Upwork are good places to find gigs. It pays well and can be quite rewarding creatively.</p>

<h2>10. Virtual Assistant</h2>
<p>Virtual assistants handle administrative tasks like email management, booking appointments, and scheduling. You can earn between $10 and $50 per hour. Companies like Zirtual and Belay Solutions are always on the lookout for new talent. For a comprehensive guide, check out Gina Horkey’s virtual assistant training course.</p>

<h2>11. Data Entry</h2>
<p>Data entry is straightforward and doesn’t require any special skills. If you’re good with spreadsheets, you’re all set. Expect to make around $10-$15 per hour. Platforms like The Smart Crowd and Dion Data are good places to start.</p>

<h2>12. Search Engine Evaluator</h2>
<p>Help improve search engine results by analyzing and rating them. If you’re good at research and staying up-to-date with trends, this is a perfect gig. It pays about $12-$15 per hour, and companies like Lionbridge and Appen are frequently hiring.</p>

<h2>13. Website/App Testing</h2>
<p>Get paid to test websites and apps for usability. Your feedback helps improve user experience. Each test takes about 15-20 minutes and pays around $7-$20. Companies like UserTesting and Userlytics are great places to find these gigs.</p>

<h2>14. Translation</h2>
<p>If you’re bilingual or multilingual, translation is a gold mine. Companies like Translate.com and Gengo are always looking for translators. You can comfortably make a good living, translating content between languages.</p>

<h2>15. Graphic Designer</h2>
<p>Use your creativity to design logos, t-shirts, and more. Graphic design gigs can pay anywhere from $25 to $300 depending on the project. Platforms like TopTal and 99designs offer plenty of opportunities.</p>

<h2>16. Paid Research Studies</h2>
<p>Participate in research studies and focus groups to earn some serious cash. You can make up to $150 per hour! Companies like Prolific and Respondent offer these high-paying gigs.</p>

<h2>17. Voice Over Artist</h2>
<p>Got a voice that could melt butter? Become a voice-over artist. You’ll provide voice for animations, audiobooks, and commercials. Gigs can pay between $50 and $300 per hour of audio. Check out platforms like Voices.com and Snap Recordings.</p>

<h3>Final Thoughts</h3>
<p>With so many options, there’s no excuse to be broke in college. Whether you’re looking to pay off your student loans or just need extra cash for the weekend, these online jobs offer flexibility and decent pay. Pick a couple that interest you and get started. Your future self will thank you. 💸</p>

<p><a href="#">Side Hustles</a>, <a href="#">Work From Home</a>, <a href="#">Earn Money</a></p>
      `,
      postSlug: 'top-17-online-jobs-for-college-students',
    };
  },
}
</script>