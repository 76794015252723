
<template>
  <blog-post-template
    :postId="postId"
    :postTitle="postTitle"
    :postContent="postContent"
    :postSlug="postSlug"
  ></blog-post-template>
</template>

<script>
/* eslint-disable */
import BlogPostTemplate from '@/components/BlogPostTemplate.vue';

export default {
  components: {
    BlogPostTemplate,
  },
  props: {
    postId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      postId: '58',
      postTitle: `Privacy Policy Unveiled`,
      postContent: `
        <p>Welcome to the world of WorkAtHomeSmart.com, where we take your privacy as seriously as a cat takes a laser pointer. This post walks you through our privacy policies, ensuring you\'re in the loop about how we handle your personal information. 📜</p>

<h2>Information Collection and Use</h2>

<p>When you grace us with your presence on WorkAtHomeSmart.com, we might ask for a tidbit or two of information to identify or contact you. This "Personal Information" could be anything from your IP address to the type of browser you’re using. Think of it as our way of saying, "Hey, we see you!" 🐱‍👓</p>

<h3>Log Data</h3>

<p>Every time you drop by, your browser sends us a love letter in the form of "Log Data." This tells us your IP address, browser type and version, the pages you visit, and timestamps for your visits. It\'s like a guestbook for the digital age.</p>

<h3>Cookies</h3>

<p>Ah, cookies! Not the chocolate chip kind, unfortunately. These little data files help us understand your preferences and improve your experience. But hey, if you’re not into cookies, you can tell your browser to reject them. Just know that some features might play hard to get if you do. 🍪</p>

<h2>Service Providers</h2>

<p>We sometimes enlist third-party wizards to help operate our site. These folks might access your Personal Information, but only to do their magic. They’re sworn to secrecy and won\'t spill your secrets or use your data for their own gain.</p>

<h2>Security</h2>

<p>Your Personal Information’s safety is our top priority. We use best practices to keep your data safe and sound. However, let\'s be real; no online fortress is impenetrable. We’ll do our best to keep the hackers at bay, but we can\'t offer a 100% foolproof guarantee.</p>

<h2>Links to Other Sites</h2>

<p>Our site might occasionally show you a shiny link to another website. If you click it, you\'re on your own! We don’t control these third-party sites and their privacy practices, so proceed with caution and maybe wear digital sunscreen. 🕶️</p>

<h2>Children’s Privacy</h2>

<p>Sorry kids, WorkAtHomeSmart.com is an adults-only (18+) club. We don’t knowingly collect information from minors. If you\'re a parent and find that your child has snuck in, let us know, and we\'ll promptly escort them out.</p>

<h2>Changes to This Privacy Policy</h2>

<p>We might tweak this Privacy Policy from time to time. When we do, we\'ll post the updated version right here. So, keep an eye out for any changes, just like you would for a plot twist in your favorite Netflix series.</p>

<h2>Contact Us</h2>

<p>If you have any burning questions about this Privacy Policy, feel free to reach out. We’re here to help and maybe even crack a joke or two.</p>

<p>Curious about more ways to <a href="financial-planning">change your life</a>? Dive into our latest posts and discover how you can navigate the path to financial freedom!</p>

<p>Tags: <a href="financial-independence">Financial Independence</a>, <a href="build-wealth">Build Wealth</a>, <a href="work-from-home">Work From Home</a></p>

<p>Copyright © 2024 WorkAtHomeSmart.com</p>
      `,
      postSlug: 'privacy-policy-unveiled',
    };
  },
}
</script>