
<template>
  <blog-post-template
    :postId="postId"
    :postTitle="postTitle"
    :postContent="postContent"
    :postSlug="postSlug"
  ></blog-post-template>
</template>

<script>
/* eslint-disable */
import BlogPostTemplate from '@/components/BlogPostTemplate.vue';

export default {
  components: {
    BlogPostTemplate,
  },
  props: {
    postId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      postId: '38',
      postTitle: `15 Easy Captioning Jobs`,
      postContent: `
        <p>Want to start a career in captioning and work from the comfort of your couch (or bed, no judgment here)? You\'ve hit the jackpot. We\'re diving into the best companies offering legit captioning jobs for beginners and pros. So, grab your favorite snack, and let\'s get cracking! 🍿</p>

<h2>What\'s a Captioner?</h2>

<p>If you\'ve ever watched a movie or a TV show with words magically appearing at the bottom of the screen, you\'ve witnessed the handiwork of a captioner. Captioners create these subtitles to make content accessible to everyone, including those with hearing impairments. 🎬</p>

<h2>How to Become a Captioner</h2>

<p>Captioning is a fantastic side hustle, and it\'s a lot easier to get started than you might think. Approach YouTube channels, directors, or producers and offer your services. Cold pitching works wonders, trust me. Now, let’s see the different types of captioning jobs you can dive into.</p>

<h3>Types of Captioning Jobs</h3>

<ul>
  <li><strong>Offline Closed Captioning:</strong> This involves pre-recorded content like movies or YouTube videos. No live action here; you can take your sweet time.</li>
  <li><strong>Real-time Captioning:</strong> This one’s for the pros. It involves captioning live shows and events, requiring speed and accuracy. Think of it as the Olympics of captioning. 🥇</li>
</ul>

<h2>How Much Can You Make?</h2>

<p>How fat will your wallet get? Captioning gigs typically pay between $10 and $40 per hour of video. Your monthly earnings will depend on how many hours you put in. Simple math, right?</p>

<h2>Legit Companies Offering Captioning Jobs</h2>

<h3>1. 3PlayMedia</h3>
<p>3PlayMedia hires both captioners and transcriptionists. Native English speakers can join as editors and work from home. They also offer medical benefits. 🏥</p>

<h3>2. Aberdeen</h3>
<p>Aberdeen focuses on Christian content. If you know your way around Christian terminologies, this is your gig. Real-time captioners can earn up to $75 per hour, while offline captioners make between $12 and $15 per hour.</p>

<h3>3. AI Media</h3>
<p>AI Media works with big names like Shopify and Amazon. You can earn up to $42 per hour, but they mostly hire UK and Australian residents.</p>

<h3>4. Caption Max</h3>
<p>Caption Max hires both real-time and offline captioners. No degree needed for offline gigs, but real-time captioners need a specialized degree.</p>

<h3>5. Caption Colorado</h3>
<p>Caption Colorado is for real-time captioners who can pass a 30-minute accuracy test. They offer both full-time and part-time positions with benefits like vacation pay.</p>

<h2>Freelance Captioning Jobs</h2>

<h3>6. CaptioningStar</h3>
<p>CaptioningStar works with big clients like the Grammy Awards and CNBC. You need to be 99% accurate, but you can earn $60 to $70 per hour. 💰</p>

<h3>7. Netflix</h3>
<p>Yes, Netflix hires captioners! Keep an eye on their careers page for remote opportunities.</p>

<h3>8. VITAC</h3>
<p>VITAC serves clients like Lifetime and BBC America. They require a Bachelor\'s degree for closed captioning gigs and a typing speed of 225 words per minute for real-time captioning.</p>

<h3>9. ACS Services</h3>
<p>ACS Services works with major news networks like CNN and ABC. They prefer candidates with a background in Journalism or English.</p>

<h3>10. National Captioning Institute</h3>
<p>NCI offers opportunities for voice writers and court reporters. They provide paid training and benefits like insurance. 🎓</p>

<h2>More Captioning Jobs for Beginners</h2>

<h3>11. Rev</h3>
<p>Rev is beginner-friendly but doesn’t pay as much. You can make $0.40 to $0.75 per video minute, paid weekly via PayPal.</p>

<h3>12. Caption Media Group</h3>
<p>Caption Media Group offers offline captioning gigs for those with at least two years of experience. They hire US-based captioners only.</p>

<h3>13. Communication Service for the Deaf (CSD)</h3>
<p>CSD focuses on improving communication for the deaf. Starting pay is $11.40 per video hour, with raises twice a year. You must work weekends and holidays.</p>

<h3>14. CopyTalk Business Services</h3>
<p>CopyTalk specializes in financial content. Beginners start at $8.25 per hour, with pay increasing post-training. 📈</p>

<h3>15. DotSub</h3>
<p>DotSub is ideal for bilingual or multilingual individuals. Native English speakers can apply via their website.</p>

<h2>Ready to Dive In?</h2>

<p>Captioning can be a rewarding freelance job. Try signing up with 3-5 of these companies to find your perfect fit. Which gig will you try first? Let us know in the comments! 🗨️</p>

<a href="/Work-From-Home">Work From Home</a>
<a href="/Online-Jobs">Online Jobs</a>
<a href="/Side-Hustles">Side Hustles</a>
      `,
      postSlug: '15-easy-captioning-jobs',
    };
  },
}
</script>