<!-- BlogPage.vue -->
<template>
  <div class="blog-page">
    <h1 class="blog-title">Blog Posts</h1>
    <div class="blog-grid">
      <div v-for="post in posts" :key="post.id" class="blog-item">
        <router-link :to="'/blog/' + post.id">
          <img :src="post.imageUrl" :alt="post.title" class="blog-thumbnail" />
          <h2 class="blog-name">{{ post.title }}</h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      posts: [],
    };
  },
  created() {
    this.loadBlogPosts();
  },
  methods: {
    loadBlogPosts() {
      const context = require.context('@/components/posts', false, /\.vue$/);
      const blogPosts = context.keys().map((key) => {
        const component = context(key).default;
        const searchTerm = component.data().postTitle.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
        return {
          id: key.replace(/^\.\//, '').replace(/\.vue$/, ''),
          title: component.data().postTitle,
          imageUrl: `/pictures/${searchTerm}.jpg`,
        };
      });
      this.posts = blogPosts;
    },
  },
};
</script>


<style scoped>
.blog-title {
  color: #000000;
  padding: 80px;
  margin: unset;
  text-align: center;
  font-size: 36px;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.blog-page {
  padding: 100px;
  min-height: 100vh;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

@media (max-width: 1200px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 992px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.blog-item {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.blog-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog-name {
  font-size: 18px;
  font-weight: bold;
}
</style>