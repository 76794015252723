<template>
  <div id="app">
    <transition name="fade" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </div>
</template>


<script>

export default {
  name: 'App',
}
</script>

<style>

#app {
    background-color: #8b8d7b;
    min-height: 100vh;
    background-image: linear-gradient(#d7d7d0, rgba(139, 141, 123, 0.85)), url('./assets/noise.jpg');
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 32px;
    font-size: 20px;
}


.component {
  width: 100%;
}


.background-image {
  width: 100%;
  height: 100vh;
  background-image: url('./assets/background4.JPG');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  filter: saturate(110%);
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  animation: fadeInOut 1.5s ease-in-out forwards;
  pointer-events: none; 
}


@keyframes fadeInOut {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


/* .background-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px; 
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(139, 141, 123, 1));
  pointer-events: none;
} */

.text-overlay {
  text-align: center;
  opacity: 0;
  animation: fade-slide-down 1s ease-out forwards;
  z-index: 100;
}

.name {
  color: #ffffff;
  font-size: 13rem;
  font-weight: bold;
}

.section-1 {
  width: 100%;
  min-height: 100vh;
  background-color: #8b8d7b;
  background-image: linear-gradient(rgba(139, 141, 123), rgba(139, 141, 123, 0.85)), url('./assets/noise.jpg');
  background-position: center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.section-2 {
  width: 100%;
  min-height: 100vh;
  background-color: #474e3d;
  background-image: linear-gradient(rgba(71, 78, 61), rgba(71, 78, 61, 0.85)), url('./assets/noise.jpg');
  background-position: center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.text-container {
  max-width: 60%;
  margin: 0 auto;
  font-size: 3.5rem;
  line-height: 1.6;
  text-align: left;
  white-space: pre-wrap;
}

.text-effect {
  color: #ccc;
}

.text-effect span {
  color: #ccc;
  transition: color 0.5s ease;
}

.text-effect.observed span {
  color: #000;
}

@keyframes fade-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.section-2 {
  width: 100%;
  min-height: 100vh;
  background-color: #474e3d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.content-container {
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
}
h2 {
  font-size: 2rem;
  margin-bottom: 40px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 40px;
}

.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
h3 {
  font-size: 1.5rem;
  color: #474e3d;
  margin-bottom: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #474e3d;
}
li {
  margin-bottom: 10px;
}


.offer {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}
.additional-info {
  font-size: 1rem;
  color: #bfbfbf;
}


.section-2 {
  width: 100%;
  min-height: 100vh;
  background-color: #474e3d;
  background-image: linear-gradient(rgba(71, 78, 61), rgba(71, 78, 61, 0.85)), url('./assets/noise.jpg');
  background-position: center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.text-container {
  max-width: 60%;
  margin: 50px auto;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  white-space: pre-wrap;
}

.text-effect {
  color: #ccc;
}

.text-effect span {
  color: #ccc;
  transition: color 0.5s ease;
}

.text-effect.observed span {
  color: #000;
}

@keyframes fade-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.section-2 {
  width: 100%;
  min-height: 100vh;
  background-color: #474e3d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.content-container {
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 40px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
h3 {
  color: #474e3d;
  margin-bottom: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #474e3d;
}
li {
  margin-bottom: 10px;
}


.offer {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}
.additional-info {
  font-size: 1rem;
  color: #bfbfbf;
}


.site-title, .site-title a, .site-title a:hover {
    font: normal 40px Libre Baskerville, Baskerville, serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    color: #222;
    text-align: center;
    margin-bottom: 5px;
}

.site-description {
    margin-bottom: 0 !important;
}

.site-description {
    color: #222;
    font: italic 14px Libre Baskerville, serif;
    letter-spacing: 2px;
    text-align: center;
    text-transform: lowercase;
    margin-top: 10px;
}
p {
    margin-top: 2.14em;
    padding: 0;
}

a {
    color: #222222;
    text-decoration: none;
}

.nav-li {
  padding: 10px;
    font-size: 20px;
    color: #222;
    display: block;
    font: normal 14px Poppins Regular, sans-serif;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}

.nav-li:hover a {
    color: #b5b5b5;
}

.more-link {
  color: #fff;
    background: #222222e0;
    padding: 15px;
    display: block;
    font: normal 11px Poppins Regular, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 auto;
    text-align: center;
    width: 150px;
    transition: .3s ease-in-out;
    
}

.more-link:hover {
  background-color: #ffffff;
  color: black;
  opacity: .5;
  transition: .3s ease-in-out;
}


.centered-div h2 a {
    font: normal 18px Libre Baskerville, serif;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.centered-div p {
    margin-top: -5px !important;
    margin-bottom: 25px !important;
    font-size: 13px;
    text-align: center;
    line-height: 2;
    color: #444444;
}

.nav-secondary .wrap {
  /* border-top: 1px solid #eee9e8;
  border-bottom: 1px solid #eee9e8; */
  margin-bottom: 30px;
  background-color: #8c8d7b;
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  justify-content: center;
}

.nav-secondary {
 
  background-color: #8c8d7b;
}
ol, ul {
    margin: 0;
    padding: 0;
    color: #222;
}

.genesis-nav-menu {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 800px; /* Adjust this value based on your desired maximum width */
  padding-top: 50px;
}


.genesis-nav-menu a {
    color: #222;
    display: block;
    font: normal 11px Poppins Regular, sans-serif;
    letter-spacing: 2.5px;
    padding: 25px 30px 16px;
    text-transform: uppercase;
    margin-bottom: 1px;
    -webkit-font-smoothing: antialiased;
    transition: .3s ease-in-out;
    font-size: 14px;
    font-weight: bold;
}

.menu-item {
  background: #5454544f;
    border-radius: 5px;
    margin-bottom: 0px;
    /* width: 150px; */
    height: 70px;
    border: #626262 solid thin;
}

.genesis-nav-menu>.menu-item:hover>a,
.genesis-nav-menu>.menu-item>a.active {
  color: #ffffff;
  scale: 1.1;
  text-decoration: underline;
  transition: .3s ease-in-out;
}

.email-subscription {
  padding: 2% !important;
    background: #f7f4f3;
    width: 65%;
    margin: 0 auto;
}

.amelia-home .home-area .enews .widget-title {
    float: left;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 15px 30px 20px !important;
    margin: 5px 30px 0 0;
    border-right: 1px solid #eee9e8;
}

.home-area .widget-title {
    text-align: center;
}
.widget-full .widget-title {
    margin-bottom: 20px;
}





.widget-title {
    text-align: center;
}
.widget-full .widget-title {
    margin-bottom: 20px;
}

input {
  background-color: #fff;
    border: 1px solid #eee9e8;
    border-radius: 0;
    box-shadow: none;
    color: #222;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .05em;
    padding: 14px;
    width: 15%;
    margin: 0px 10px;
}


.fade-enter-active,
.fade-leave-active {
  transition: .3s ease-in-out;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: .3s ease-in-out;
}

.text-overlay {
  text-align: center;
  opacity: 0;
  animation: fade-slide-down 1s ease-out forwards;
  position: relative;
  top: -100px; /* Adjust this value to move the name higher */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 400px;
}

.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 60px;
  border-radius: 40px;
  background-color: #eaff82;
  color: #202020;
  font-size: 1rem;
  letter-spacing: 4px;
  font-family: 'Poppins Regular';
  text-decoration: none;
  margin: 0 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #d4e86c;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.subtitle {
  color: #ffffff;
  letter-spacing: 5px;
  font-size: 1.5rem;
  font-family: 'Poppins Regular';
}

.name {
  font-family: Peachy;
  font-weight: 100;
  margin-bottom: 0;
  line-height: .8;
  position: relative;
  transition: transform 0.3s ease-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.section-3 {
  width: 100%;
  min-height: 100vh;
  background-color: #474e3d;
  background-image: linear-gradient(#6b755d, rgba(71, 78, 61, 0.85)), url('./assets/noise.jpg');
  background-position: center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.section-3 .grid-container {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.section-3 .grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 100px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-3 h3 {
  margin-bottom: 10px;
  padding-top: 20px;
}

.section-3 p {
  font-size: 14px;
  line-height: 1.6;
}

@font-face {
  font-family: 'Peachy';
  src: url('./assets/fonts/Peachy Fantasy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yavome';
  src: url('./assets/fonts/Yavome.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Unknow';
  src: url('./assets/fonts/unknow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PowerPassionDemo';
  src: url('./assets/fonts/PowerPassionDemoRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 6rem;
  }

  .subtitle {
    font-size: 1rem;
  }
  
  .text-container, .text-container1, .text-container2 {
    max-width: 90%;
    font-size: 1rem;
    margin: 0 auto;
  }

  .text-container p {
    font-size: 16px;
  }

  .ihp-text-container {
    max-width: 90% !important;
  }

  .ihp-text-container p {
    font-size: 12px;
  }

  .genesis-nav-menu {
    display: flex;
    justify-content: space-between;
    width: unset;
    max-width: 800px; /* Adjust this value based on your desired maximum width */
  }
  
  .grid-container {
    grid-template-columns: 1fr;
  }

  .genesis-nav-menu a {
    font-size: 10px !important; /* Adjust the font size for mobile */
    padding: 15px; /* Adjust the padding for mobile */
  }
  body {
    overflow-x: hidden;
  }

  .ihp-page, .blog-page, .about-page, .contact-page {
    padding: 20px !important;
  }

  .content-container {
    max-width: 90%;
  }
  
  .custom-button {
    width: 250px;
    height: 50px;
    font-size: 0.8rem;
    margin: 10px 20px;
  }


  .section-3 .grid-item {
    padding: 20px;
  }

  .section-3 h3 {
    padding-top: 20px;
    font-size: 20px;
  }

  .section-3 h2 {
    font-size: 30px;
  }


  .button-container {
    display: inline-block;
    margin-top: 40%;
  }
  
}

@media screen and (max-width: 768px) {
  .email-subscription {
    width: 90%;
  }
  
  input {
    width: 100%;
    margin: 10px 0;
  }
  
  .enews-submit {
    width: 100%;
  }
}
</style>