/* eslint-disable */
// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/HomePage.vue';
import Blog from './components/BlogPage.vue';
import BlogPostTemplate from './components/BlogPostTemplate.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/blog', component: Blog },
  {
    path: '/blog/:postSlug',
    component: BlogPostTemplate,
    props: route => ({
      postId: route.params.postSlug
    }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;