
<template>
  <blog-post-template
    :postId="postId"
    :postTitle="postTitle"
    :postContent="postContent"
    :postSlug="postSlug"
  ></blog-post-template>
</template>

<script>
/* eslint-disable */
import BlogPostTemplate from '@/components/BlogPostTemplate.vue';

export default {
  components: {
    BlogPostTemplate,
  },
  props: {
    postId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      postId: '96',
      postTitle: `Contact Norbert: Your Go-To Guy`,
      postContent: `
        <p>Hey there, fabulous reader! Norbert here, your friendly neighborhood finance guru. Got burning questions? Want to see something specific on this site? Or perhaps you\'re looking to partner up and create some magic? Well, don\'t be shy—I\'d absolutely love to hear from you. Let\'s make it happen! 💌</p>

<p>If you have any inquiries, feel free to shoot me an email at the address below. Seriously, I\'m all ears!</p>

<p>Email: <a href="mailto:[email protected]">[email protected]</a></p>

<h2>Latest Posts</h2>

<p>Stay tuned, because exciting new content is always just around the corner! Whether you\'re looking to master the art of <a href="/earn-money">earning money</a>, find the best <a href="/side-hustles">side hustles</a>, or learn the secrets of <a href="/financial-independence">financial independence</a>, we\'ve got you covered.</p>

<h2>Affiliate Disclosure</h2>

<p>Alright, let\'s get real for a minute. Some of the links on our site are affiliate links. This means if you click on one of those shiny links and purchase something, we might get a little commission. But fear not! All opinions are 100% our own. We don\'t accept payments for positive reviews—I mean, who does that? 😉</p>

<h2>Connect With Us</h2>

<p>Join our community and stay in the loop. Follow us on social media for daily tips, tricks, and a sprinkle of wit to brighten your day.</p>

<p>Copyright © 2024 WorkAtHomeSmart.com</p>

<p><a href="/financial-independence">#Financial Independence</a> <a href="/side-hustles">#Side Hustles</a> <a href="/earn-money">#Earn Money</a></p>
      `,
      postSlug: 'contact-norbert-your-go-to-guy',
    };
  },
}
</script>