<template>
  <div class="blog-post">
    <div class="ad-sidebar">
      <div id="ad-slot-sidebar"></div>
    </div>
    <h1>{{ post.title }}</h1>
    <img :src="post.imageUrl" :alt="post.title" class="blog-post-image" />
    <div v-html="post.content"></div>
    <div class="related-posts">
      <h2>Related Posts</h2>
      <ul>
        <li v-for="relatedPost in relatedPosts" :key="relatedPost.id">
          <router-link :to="'/blog/' + relatedPost.slug">{{ relatedPost.title }}</router-link>
        </li>
      </ul>
    </div>
    <div class="ad-bottom">
      <div id="ad-slot-bottom"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    postId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      relatedPosts: [],
      post: {
        id: this.postId,
        title: '',
        content: '',
        imageUrl: '',
      },
    };
  },
  created() {
    this.loadBlogPost();
    this.loadRelatedPosts();
  },
  watch: {
    postId(newPostId) {
      this.post.id = newPostId;
      this.loadBlogPost();
    },
  },

  methods: {
    loadBlogPost() {
      const blogPost = require(`@/components/posts/${this.postId}.vue`).default;
      this.post.title = blogPost.data().postTitle;
      this.post.content = blogPost.data().postContent;

      // Generate the image URL using the relative path
      const searchTerm = this.post.title.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
      this.post.imageUrl = `/pictures/${searchTerm}.jpg`;
    },
    loadRelatedPosts() {
      const context = require.context('@/components/posts', false, /\\.vue$/);
      const blogPosts = context.keys().map((key) => {
        const component = context(key);
        if (component) {
          const defaultExport = component.default;
          return {
            id: key.replace(/^\\.\\//  '').replace(/\\.vue$/, ''),
            slug: defaultExport.data().postSlug,
            title: defaultExport.data().postTitle,
          };
        }
        return null;
      }).filter(Boolean);
      
      // Exclude the current post from the related posts
      this.relatedPosts = blogPosts.filter(post => post.id !== this.postId);
    },
  },
};
</script>

<style scoped>
.ad-sidebar {
  margin-bottom: 20px;
  text-align: center;
}

.ad-bottom {
  margin-top: 20px;
  text-align: center;
}

.blog-post-image {
  max-width: 100%;
  max-height: 50vh;
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>