<template>
  <div class="component">
    <div class="background-image">
      <img class="background-image" src="../assets/background3.JPG" alt="Background Image">

      <video autoplay loop muted playsinline>
        <source src="../assets/grain.mov" type="video/mp4">
      </video>

      <div class="text-overlay">
        <h1 class="name">
          <span>MONEY</span>
          <br>
          <span>MONEY</span>
        </h1>
        <span class="subtitle">FINANCIAL INDEPENDENCE RETIRE EARLY</span>
        <div class="wrap">
              <ul class="genesis-nav-menu">
                <li class="menu-item">
                    <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                </li>
              </ul>
          </div>
        
      </div>
    </div>
  </div>
  <div class="blog-page">
    <h1 class="blog-title">Blog Posts</h1>
    <div class="blog-grid">
      <div v-for="post in posts" :key="post.id" class="blog-item">
        <router-link :to="'/blog/' + post.id">
          <img :src="post.imageUrl" :alt="post.title" class="blog-thumbnail" />
          <h2 class="blog-name">{{ post.title }}</h2>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'App',
  mounted() {
    this.setupScrollEffect();
    this.setupMouseMoveEffect();
    this.loadBlogPosts();

  },
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    setupScrollEffect() {
      const textContainers = gsap.utils.toArray('.text-effect');

      textContainers.forEach(container => {
        const letters = container.innerText.split('');
        container.innerHTML = letters.map(letter => `<span>${letter}</span>`).join('');

        gsap.fromTo(container.children, {
          color: '#848676'
        }, {
          color: '#d2d2d2',
          duration: 1,
          stagger: 0.05,
          ease: 'power2.inOut', // Add ease-in-out effect
          scrollTrigger: {
            trigger: container,
            start: 'top 80%',
            end: 'bottom 50%',
            scrub: 1 // Adjust the scrub value for smoother behavior
          }
        });
      });
    },
    loadBlogPosts() {
      const context = require.context('@/components/posts', false, /\.vue$/);
      const blogPosts = context.keys().map((key) => {
        const component = context(key).default;
        const searchTerm = component.data().postTitle.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
        return {
          id: key.replace(/^\.\//, '').replace(/\.vue$/, ''),
          title: component.data().postTitle,
          imageUrl: `/pictures/${searchTerm}.jpg`,
        };
      });
      this.posts = blogPosts;
    },
    setupMouseMoveEffect() {
      const nameElement = document.querySelector('.name');
      const textOverlay = document.querySelector('.text-overlay');

      textOverlay.addEventListener('mousemove', (event) => {
        const { clientX, clientY } = event;
        const { offsetWidth, offsetHeight } = textOverlay;
        const centerX = offsetWidth / 2;
        const centerY = offsetHeight / 2;
        const moveX = (clientX - centerX) / centerX;
        const moveY = (clientY - centerY) / centerY;

        nameElement.style.transform = `translate(${moveX * 3}px, ${moveY * 3}px)`;
      });

      textOverlay.addEventListener('mouseleave', () => {
        nameElement.style.transform = 'translate(0, 0)';
      });
    },
  }
}
</script>

<style scoped>

.blog-title {
  color: #000000;
  padding: 80px;
  margin: unset;
  text-align: center;
  font-size: 36px;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.blog-page {
  padding: 100px;
  min-height: 100vh;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

@media (max-width: 1200px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 992px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.blog-item {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.blog-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog-name {
  font-size: 18px;
  font-weight: bold;
}
</style>